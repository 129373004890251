<script setup>
const props = defineProps({
  imgUrlRewrite: { type: Boolean, required: true, default: true },
  src: { type: String, required: true },
  alt: { type: String, required: true },
  options: { type: [Object, String], required: true },
  sizes: { type: String, default: null },
  srcSetOptions: { type: Object, default: null },
  loading: { type: String, default: null },
  width: { type: [Number, String], default: null },
  height: { type: [Number, String], default: null },
})

const computedSrc = computed(() => {
  if (!props.imgUrlRewrite) {
    return props?.src
  }
  return getUrlWithOptions({ src: props?.src }, props.options)
})

const computedSrcSet = computed(() => {
  if (props.srcSetOptions === null || Object.keys(props.srcSetOptions).length === 0) {
    return null
  }
  const srcSetUrls = []
  if (!props.imgUrlRewrite) {
    for (const [key, options] of Object.entries(props.srcSetOptions)) {
      srcSetUrls.push(`${options} ${key}w`)
    }
    return srcSetUrls.join(', ')
  }

  for (const [key, options] of Object.entries(props.srcSetOptions)) {
    srcSetUrls.push(`${getUrlWithOptions({ src: props?.src }, { ...props.options, ...options })} ${key}w`)
  }
  return srcSetUrls.join(', ')
})

</script>

<template>
  <img
    :src="computedSrc"
    :alt="alt"
    :srcset="computedSrcSet"
    :sizes="imgUrlRewrite ? sizes : null"
    :loading="loading"
    :width="width"
    :height="height"
  >
</template>
